













































import { Component, Vue, Prop, Emit, Watch, Inject } from 'vue-property-decorator';
import { ADD, EDIT, COPY, DETAIL, EDITOR_CONFIG } from '@/config/constant';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: {}
})
export default class ItemDialog extends Vue {
    @Prop(String) private dialogType!: string;
    @Prop() private defaultData: object | null;
    @Prop() private levelList!: any[];
    @Prop() private assessStatus: string | undefined;
    @Emit() submit(data: any) {
        return data;
    }
    @Emit() cancel(isRefresh: boolean = false) {
        return isRefresh;
    }

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public COPY: string = COPY;
    public DETAIL: string = DETAIL;

    public formInfo: any = {
        displayOrder: 1,
    };
    public rules: any = {
        title: [
            { required: true, message: '请输入测评项名称', trigger: 'blur' },
            { max: 100, message: '长度不能超过 100 个字', trigger: ["change", "blur"] },
        ],
        // description: [
        //     { max: 1000, message: "长度不能超过 1000 个字", trigger: ["change", "blur"] },
        // ],
        // levelsDesc: [
        //     { max: 1000, message: "长度不能超过 1000 个字", trigger: ["change", "blur"] },
        // ],
    };
    public labelPosition: String = 'right';
    public title: string = '';
    public tableData: any[] = [];
    public loading: Boolean = false;
    public visible: boolean = true;

    // 是否是已发布状态(编辑时)
    get isReleaseStatus() {
        return this.dialogType === EDIT && this.assessStatus === "RELEASE";
    }
    // 测评项说明的富文本配置
    get ITEM_EDITOR_CONFIG() {
        return EDITOR_CONFIG({});
    }
    // 等级说明的富文本配置
    get LEVEL_EDITOR_CONFIG() {
        return EDITOR_CONFIG({ isReadonly: this.isReleaseStatus });
    }

    public created(): void {
        this.initData();
    }
    // 初始化数据
    public initData() {
        switch (this.dialogType) {
            case ADD:
                this.title = '新增测评项';
                // 初始化表单
                let arr = this.levelList.map(t => ({
                    skillLevelId: t.id,
                    skillLevelTitle: t.title,
                    displayOrder: t.displayOrder,
                    description: ""
                }))
                this.$set(this.formInfo, "typeItemLevels", deepCopy(arr));
                console.log("levelList", this.levelList, this.formInfo);
                break;
            case EDIT:
                this.title = '编辑测评项';
                // 初始化表单
                this.formInfo = deepCopy(this.defaultData);
                console.log("初始化表单 formInfo", this.formInfo);
                break;
        }
    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel();
        } catch (e) { }
    }
    // 保存表单
    public submitForm(): void {
        (this.$refs.formInfo as any).validate(async (valid: any) => {
            if (!valid) return;
            this.$message({
                message: this.dialogType === ADD ? '添加成功' : '修改成功',
                type: 'success'
            });
            this.submit(this.formInfo);
            this.cancel(true);
        });
    }
}
