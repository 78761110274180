import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class BasicInformationService {
  @Inject(HttpService) private http!: HttpService;
  // 获取状态
  public getAssessmentStatus(param?: Dict<any>): Promise<any> {
    return this.http.get('/wecom-skill-backend/admin/assessment/status', param);
  }
  // 添加
  public createAssessment(param: Dict<any>): Promise<any> {
    return this.http.post(
      '/wecom-skill-backend/admin/assessment/create',
      param
    );
  }
  // 粘贴
  public pasteAssessment(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/assessment/paste', param);
  }
  // 编辑
  public updateAssessment(param: Dict<any>): Promise<any> {
    return this.http.post(
      '/wecom-skill-backend/admin/assessment/update',
      param
    );
  }
  // 查询
  public getAssessmentList(param: Dict<any>): Promise<any> {
    return this.http.post(
      '/wecom-skill-backend/admin/assessment/listPage',
      param
    );
  }
  // 详情
  public getDetailById(id: string | number): Promise<any> {
    return this.http.get(`/wecom-skill-backend/admin/assessment/detail/${id}`);
  }
  // 预览
  public previewAssessment(param: Dict<any>): Promise<any> {
    return this.http.post(
      '/wecom-skill-backend/admin/assessment/preview',
      param
    );
  }
  // 停止预览
  public stopPreviewAssessment(id: string | number): Promise<any> {
    return this.http.put(
      `/wecom-skill-backend/admin/assessment/preview/stop?assessmentId=${id}`
    );
  }
  // 发布
  public releaseAssessment(param: Dict<any>): Promise<any> {
    return this.http.post(
      '/wecom-skill-backend/admin/assessment/release',
      param
    );
  }
  // 下架
  public archiveAssessment(id: string | number): Promise<any> {
    return this.http.put(`/wecom-skill-backend/admin/assessment/archive/${id}`);
  }
  // 完成
  public completeAssessment(id: string | number): Promise<any> {
    return this.http.put(
      `/wecom-skill-backend/admin/assessment/complete/${id}`
    );
  }
  // 删除
  public removeAssessmentById(id: string | number): Promise<any> {
    return this.http.delete(`/wecom-skill-backend/admin/assessment/delete/${id}`);
  }
  // 获取操作日志
  public getAssessmentLog(param: Dict<any>): Promise<any> {
    return this.http.get(
      '/wecom-skill-backend/admin/assessment/operation/history',
      param
    );
  }
}
