





















import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import UserGroupSelector from './UserGroupSelector.vue';
import { BasicInformationService } from '@/services/abilityAssessment/basic-information-service';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: { UserGroupSelector }
})
export default class ReleaseDialog extends Vue {
    @Prop() private defaultData: object;
    @Emit() cancel(isRefresh: boolean) {
        return isRefresh;
    }
    @Inject(BasicInformationService)
    private basicInformationService!: BasicInformationService;

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public DETAIL: string = DETAIL;
    public formInfo: any = {
        groups: [],
        groupIds: [],
        customGroupIds: []
    };
    public rules: any = {
        groups: [
            { required: true, message: '请选择用户组', trigger: 'blur' }
        ]
    };
    public labelPosition: String = 'right';
    public title: string = '发布';
    public visible: boolean = true;
    public unauthGroups: any[] = [];

    public created(): void {
        this.initData();
    }
    // 初始化数据
    public initData() {
    }
    // 保存用户组
    public handleGroupSubmit({ groupIds, customGroupIds }) {
        console.log("保存用户组", groupIds, customGroupIds, this.formInfo.groups);
        this.formInfo.groupIds = groupIds;
        this.formInfo.customGroupIds = customGroupIds;
        (this.$refs.formInfo as any).clearValidate("groups");
    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel(false);
        } catch (e) { }
    }
    // 保存表单
    public submitForm(): void {
        (this.$refs.formInfo as any).validate(async (valid: any) => {
            if (!valid) return;
            const { id } = this.defaultData as any;
            const params = {
                assessmentId: id,
                groupIds: this.formInfo.groupIds.length ? this.formInfo.groupIds.map(t => t.toString()) : [],
                customGroupIds: this.formInfo.customGroupIds.length ? this.formInfo.customGroupIds.map(t => t.toString()) : [],
            };
            const res = await this.basicInformationService.releaseAssessment(params);
            if (res) {
                this.$message({
                    message: '操作成功',
                    type: 'success'
                });
                this.cancel(true);
            }
            console.log('params', params);
        });
    }
}
