
















































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import { BasicInformationService } from '@/services/abilityAssessment/basic-information-service';
import { massSet } from '@/services/massage-service';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: {}
})
export default class PreviewDialog extends Vue {
    @Prop() private defaultData: object;
    @Emit() cancel(isRefresh: boolean) {
        return isRefresh;
    }
    @Inject(BasicInformationService)
    private basicInformationService!: BasicInformationService;
    @Inject(massSet) private massSet!: massSet;

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public DETAIL: string = DETAIL;
    public userDisabledShow: Boolean = false;
    public noNumshow: Boolean = false;
    public userParams: any = {
        totalNum: 0, // 总个数
        noNum: 0, // 无效个数
        useNum: 0, // 有效个数
    }
    public managerDisabledShow: Boolean = false;
    public checkCompleted: Boolean = false;
    public checkPassed: Boolean = false;
    public managerParams: any = {
        totalNum: 0, // 总个数
        noNum: 0, // 无效个数
        useNum: 0, // 有效个数
    }
    public formInfo: any = {
        userListStr: '',
        manager: "",
    };
    public rules: any = {
        userListStr: [
            { required: true, message: '请输入用户邮箱或工号', trigger: 'blur' }
        ],
        manager: [
            { required: true, message: '请输入直接上级邮箱或工号', trigger: 'blur' }
        ],
    };
    public labelPosition: String = 'top';
    public title: string = '预览';
    public visible: boolean = true;

    public created(): void {
        this.initData();
    }
    // 初始化数据
    public initData() {
    }
    // 改变用户textarea
    public userListStrWrite(e: any) {
        let strLength = [], Arr_ = [];
        this.noNumshow = false;
        if (this.formInfo.userListStr == '' || this.formInfo.userListStr == null || this.formInfo.userListStr == undefined) {
            this.userParams.totalNum = 0;
        } else {
            strLength = this.formInfo.userListStr.split(',');
            Arr_ = Array.from(new Set(strLength));
            this.formInfo.userListStr = Arr_.toString();
            this.userParams.totalNum = Arr_.length;
        }
        if (this.userParams.totalNum > 0) {
            this.userDisabledShow = true;
        }
    }
    // 改变上级input
    public managerStrWrite(e: any) {
        if (!e) {
            this.managerParams.totalNum = 0;
            return;
        }
        let strLength = [], Arr_ = [];
        this.checkCompleted = false;
        if (this.formInfo.manager == '' || this.formInfo.manager == null || this.formInfo.manager == undefined) {
            this.userParams.totalNum = 0;
        } else {
            strLength = this.formInfo.manager.split(',');
            Arr_ = Array.from(new Set(strLength));
            this.formInfo.manager = Arr_[0];
            this.managerParams.totalNum = 1;
        }
        if (this.managerParams.totalNum > 0) {
            this.managerDisabledShow = true;
        }
    }
    // 检测用户
    public async checkPerson(type: string): Promise<void> {
        let data_ = {};
        data_ = {
            "usersStr": this.formInfo[type],
        }
        let res: any = await this.massSet.persnSearch(data_);
        if (res) {
            if (type === "userListStr") {
                // 检测用户
                this.userParams.noNum = res.unFinds.length;
                this.userParams.useNum = res.empIds.length + res.emails.length;
                this.userParams.totalNum = res.unFinds.length + res.empIds.length + res.emails.length;
                this.noNumshow = true;
                this.formInfo.userListStr = res.usersStr;
                if (res.unFinds.length > 0) {
                    this.userDisabledShow = true;
                    this.$alert('用户：' + res.unFinds.toString() + ' 不存在', '错误用户', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                } else {
                    this.$message({
                        type: 'info',
                        message: '用户检测完成'
                    });
                    this.userDisabledShow = false;
                }
            } else {
                // 检测上级
                this.managerParams.totalNum = res.unFinds.length + res.empIds.length + res.emails.length;
                this.checkCompleted = true;
                this.formInfo.manager = res.usersStr;
                if (res.unFinds.length > 0) {
                    this.managerDisabledShow = true;
                    this.checkPassed = false;
                    this.$alert('用户：' + res.unFinds.toString() + ' 不存在', '错误用户', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                } else {
                    this.$message({
                        type: 'info',
                        message: '用户检测完成'
                    });
                    this.managerDisabledShow = false;
                    this.checkPassed = true;
                }
            }
        }
    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel(false);
        } catch (e) { }
    }
    // 保存表单
    public submitForm(): void {
        (this.$refs.formInfo as any).validate(async (valid: any) => {
            if (!valid) return;
            const { id } = this.defaultData as any;
            const params = {
                assessmentId: id,
                employees: this.formInfo.userListStr ? this.formInfo.userListStr.split(",") : [],
                reviewers: this.formInfo.manager ? [this.formInfo.manager] : []
            };
            const res = await this.basicInformationService.previewAssessment(params);
            if (res) {
                this.$message({
                    message: '操作成功',
                    type: 'success'
                });
                this.cancel(true);
            }
            console.log('params', params);
        });
    }
}
