





















































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch, Provide } from 'vue-property-decorator';
import { ADD, EDIT, COPY, DETAIL } from '@/config/constant';
import ManageDialog from './components/ManageDialog.vue';
import ReleaseDialog from './components/ReleaseDialog.vue';
import PreviewDialog from './components/PreviewDialog.vue';
import LogDialog from './components/LogDialog.vue';
import { BasicInformationService } from '@/services/abilityAssessment/basic-information-service';
import { deepCopy, keyValueConversion } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
  components: { ManageDialog, ReleaseDialog, PreviewDialog, LogDialog }
})
export default class typeManagement extends Vue {
  @Inject(BasicInformationService)
  private basicInformationService!: BasicInformationService;

  public ADD: string = ADD;
  public EDIT: string = EDIT;
  public COPY: string = COPY;
  public DETAIL: string = DETAIL;
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  public tableData: any[] = [];
  public failReason: any[] = [];
  public loading: Boolean = false;
  public statusOptions: any[] = [];
  // Dialog相关
  public dialogVisible: boolean = false;
  public releaseDialogVisible: boolean = false;
  public previewDialogVisible: boolean = false;
  public logDialogVisible: boolean = false;
  public dialogType: string = '';
  public currentRow: any = null;

  public async created() {
    // 如果列表接口可以返回状态名称，则不需要做异步处理
    await this.getStatus();
    this.getList();
  }
  // 清空数据
  public clearData() {
    this.dialogType = '';
    this.currentRow = null;
  }
  // 搜索
  public search() {
    this.form.current = 1;
    this.getList();
  }
  // 分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  // 转换状态
  public transformStatus(val: string): string {
    return keyValueConversion(val, this.statusOptions);
  }
  // 关闭新增/编辑Dialog
  public dialogCancel(isRefresh: boolean) {
    this.clearData();
    this.dialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  // 关闭发布Dialog
  public releaseDialogCancel(isRefresh: boolean) {
    this.clearData();
    this.releaseDialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  // 关闭预览Dialog
  public previewDialogCancel(isRefresh: boolean) {
    this.clearData();
    this.previewDialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  // 关闭操作日志Dialog
  public logDialogCancel() {
    this.logDialogVisible = false;
  }
  // 获取状态
  public async getStatus() {
    const res = await this.basicInformationService.getAssessmentStatus();
    if (res && res.length) {
      this.statusOptions = res.map(t => ({ label: t.displayName, value: t.name }));
    }
  }
  // 获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.basicInformationService.getAssessmentList({ ...this.form, statuses: this.form.status ? [this.form.status] : null });
    if (res) {
      this.tableData = res.records || [];
      this.form.total = res.total;
      this.form.size = res.size;
      this.form.current = res.current;
    }
    this.loading = false;
  }
  // 新增/编辑/查看
  public async handManage(type: string, row?: any) {
    this.dialogType = type;
    let res = null;
    switch (type) {
      case ADD:
        this.currentRow = null;
        this.dialogVisible = true;
        break;
      case EDIT:
        // 获取详情数据
        res = await this.basicInformationService.getDetailById(row.id);
        if (res) {
          this.currentRow = res;
          this.dialogVisible = true;
        }
        break;
      case COPY:
        // 获取详情数据
        res = await this.basicInformationService.getDetailById(row.id);
        if (res) {
          this.currentRow = res;
          this.dialogVisible = true;
        }
        break;
    }
  }
  // 删除
  public async handDelete(row: any): Promise<void> {
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      let res = await this.basicInformationService.removeAssessmentById(row.id);
      if (res) {
        this.getList();
        this.$message({
          message: '删除成功',
          type: 'success'
        });
      }
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  // 发布
  public handleRelease(row: any) {
    this.currentRow = deepCopy(row);
    this.releaseDialogVisible = true;
  }
  // 预览
  public handlePreview(row: any) {
    this.currentRow = deepCopy(row);
    this.previewDialogVisible = true;
  }
  // 操作日志
  public handleLog(row: any) {
    this.currentRow = deepCopy(row);
    this.logDialogVisible = true;
  }
  // 停止预览
  public async handleStopPreview(row: any): Promise<void> {
    try {
      await this.$confirm('请确认是否停止预览?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      let res = await this.basicInformationService.stopPreviewAssessment(row.id);
      if (res) {
        this.getList();
        this.$message({
          message: '停止预览成功',
          type: 'success'
        });
      }
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消停止预览'
      });
    }
  }
  // 下架
  public async handleArchive(row: any): Promise<void> {
    try {
      await this.$confirm('请确认是否下架，一旦下架，已有用户提交该测评单的内容将会被清除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      let res = await this.basicInformationService.archiveAssessment(row.id);
      if (res) {
        this.getList();
        this.$message({
          message: '下架成功',
          type: 'success'
        });
      }
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消下架'
      });
    }
  }
  // 完成
  public async handleComplete(row: any): Promise<void> {
    try {
      await this.$confirm('确定要完成吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      let res = await this.basicInformationService.completeAssessment(row.id);
      if (res) {
        this.getList();
        this.$message({
          message: '完成成功',
          type: 'success'
        });
      }
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消下架'
      });
    }
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }
}
